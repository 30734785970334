.ql-container{
  font-family: 'Roboto-Regular';
  font-size: 12px;
  overflow-x: auto;
}
.ql-container .ql-tooltip{
  position: fixed !important;
  top:250px !important;
  left:auto !important;
  right: 20px !important;
  margin-top: 0 !important;
}

/*font-size*/

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}


/*font-family*/

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-snow .ql-picker.ql-font {
  width: 150px !important;
  white-space: nowrap;
}